.hero-page {
  position: relative;
}

.main-hero-page {
  scroll-behavior: smooth !important;
  /* background-color: #162431; */
  position: relative;
  min-height: 100vh;
}

.hero-video-outerbox {
  min-height: 100vh;
}

/* .hero-page img {
  height: 500px;
  padding-top: 20%;
} */
.hero-video-box {
  object-fit: cover;
  overflow: hidden;
  border-radius: 50px;
  transform: rotate3d(1, 0, 1, 20deg);

  display: flex;
  align-items: center;
  justify-content: center;
}

video {
  border-radius: 50px;
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  overflow: hidden;
}

.hero-page-contentbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .hero-video-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 35%;
  bottom: 40%;
  right: 35%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
} */

.hero-page-content h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  letter-spacing: 7.5px;
  color: #ffd479;
  font-size: 96px;

  line-height: 1.1;
}
/* .hero-page-content P {
  font-style: normal;

  font-size: 18px;
  line-height: 24px;

  font-weight: 700;

  font-family: "Work Sans", sans-serif !important;
  color: #159eec;
} */

@media (min-height: 980px) {
  .main-hero-page {
    min-height: 60vh;
  }

  video {
    position: relative;
    min-height: 0vh;
    max-width: 200% !important;
  }
}

@media only screen and (max-width: 768px) {
  .hero-video-box{
    display: none;
  }
  .main-hero-page {
    background-image: url("../images/img91.jpg");
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    
  }
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    line-height: 1.1;
    color: #ffd479;
    font-size: 58px;
  }
}
@media only screen and (max-width: 414px) {
  .main-hero-page {
    padding-top: 100px;
  }
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    padding-top: 150px;
    color: #ffd479;
    font-size: 49px;
  }
}
@media only screen and (max-width: 375px) {

  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    padding-top: 150px;
    color: #ffd479;
    font-size: 49px;
  }
  .main-hero-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .hero-btn .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #162431;
    border-color: #ffc94b;
    background: #ffc94b;
    width: 100px;
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
  }

  .hero-btn .ant-btn-primary {
    color: #162431;
    border-color: #ffc94b;
    background: #ffc94b;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 100px;
    height: 60px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 20px;
    cursor: not-allowed;
    /* margin-right: 20%;
    margin-bottom: 100%; */
  }
}
@media only screen and (max-width: 390px) {
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #ffd479;
    font-size: 51px;
  }
  .main-hero-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 393px) {
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #ffd479;
    font-size: 51px;
  }
  .main-hero-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 820px) {
  .main-hero-page {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 912px) {
  .main-hero-page {
    padding-top: 100px;
  }
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #ffd479;
    font-size: 54px;
  }
}
/* @media only screen and (max-width: 360px) {
  .hero-video-box {
    display: none;
  }

  .main-hero-page {
    background-image: url("../images//img2.jpg");
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    min-height: 150px;
    object-fit: cover;
  }
} */

.hero-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #162431;
  border-color: #ffc94b;
  background: #ffc94b;
  width: 200px;
  cursor: not-allowed;
}

a {
  text-decoration: none;
}

.hero-btn .ant-btn-primary {
  color: #162431;
  border-color: #ffc94b;
  background: #ffc94b;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  width: 200px;
  height: 60px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 20px;
  cursor: not-allowed;
}


@media only screen and (max-width: 375px) {

  .hero-page-content  {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 36px;
    /* margin-top: 10%; */
    line-height: 1.1;
  }
  .main-hero-page {
    scroll-behavior: smooth !important;
    background-color: #162431;
    position: relative;
    min-height: 0vh !important;
  }
  .main-hero-page {
    padding-top: 80px;
    padding-bottom: 100px;
  }
  .hero-btn .ant-btn-primary {
    color: #162431;
    border-color: #ffc94b;
    background: #ffc94b;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 170px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 20px;
    cursor: not-allowed;
  }
}
@media only screen and (max-width: 768px) {

  .hero-video-box{
    display: none;
  }


  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 36px;
    margin-top: 30%;
    line-height: 1.1;
  }
  .main-hero-page {
    scroll-behavior: smooth !important;
    background-color: #162431;
    position: relative;
    min-height: 0vh !important;
  }
  .hero-video-outerbox {
    min-height: 0vh;
  }
}
@media only screen and (max-width: 820px) {
 
  .hero-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 36px;
    margin-top: 30%;
    line-height: 1.1;
    position: absolute;
      -webkit-animation:colorchange 20s infinite alternate;
  }
  
  .main-hero-page {
    scroll-behavior: smooth !important;
    background-color: #162431;
    position: relative;
    min-height: 50vh !important;
  }
  .hero-video-outerbox {
    min-height: 0vh;
  }
}
