.side-whoarewe-page {
  /* padding-bottom: 100px; */
  /* min-height: calc(100vh - 100px); */
  /* background-color: rgb(92 155 198); */
  background-color: #1f2a32;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
}

.Whoarewe-page h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 60px;
  align-items: center;
  /* padding-top: 90px; */
  padding-bottom: 30px;
  color: #ffd479;
}

.Whoarewe-page h3 {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 60px;
  align-items: center;

  color: #ffd479;
}
.Whoarewe-page p {
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  /* line-height: 39px; */
  text-align: justify;
  color: #fff;
  /* padding-top: 100px;
  padding-bottom: 55px; */
}

.side-Whoarewe-page {
  background: #1e1e1e;
}
@media only screen and (max-width: 1024px) {
  .Whoarewe-page {
    margin-top: 0px;
  }
  /* .Whoarewe-page img {
    width: 90%;
  } */
}
@media only screen and (max-width: 768px) {
  .Whoarewe-page img {
    width: 90%;
  }
}
.whoarewe-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  height: 40px;
  margin-right: 50%;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.whoarewe-request-button-yellow
  .whoarewe-request-button
  .ant-form-item-control-input-content {
  flex: none !important;
  max-width: 100% !important;
  text-align: left !important;
  align-items: left !important;
}
.whoarewe-img {
  transform: rotate(-20deg);
  width: 100%;
}
