.Aboutpart9-page {
  padding-top: 15px;
  background-color: #1f2a32;
  padding-bottom: 35px;
}

.imp-page h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 44px;
  color: #ffd479;
  margin-bottom: 0%;
  text-transform: uppercase;
}
.Aboutpart9-page h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 44px;
  color: #ffd479;
  
  text-transform: uppercase;
}
.Aboutpart9-page h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 58px;
  line-height: 44px;
  color: #ffd479;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.imp-page p {
  font-weight: 200;
  font-size: 20px;
  line-height: 26.28px;
  text-align: justify;
  color: #fff;
  /* padding-top: 40px; */
}
/* .supe {
  height: 50%;
  width: 20%;
} */
