.main-ServicesHero3-page {
  background-image: url("../images/img12.png");
  /* background-color: #fff; */
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  /* margin-top: 100px; */
  min-height: 50vh;
  z-index: 0;
}

/* .main-hero-page {
  background: url("../../images/img2.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 100px);
  
} */
/* .main-hero-page::after {
  content: "";
  position: absolute;
  min-height: calc(100vh - 100px);
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
} */

.ServicesHero3-page-content {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  /* padding-top: 10%; */
  padding-left: 20px;
  z-index: 10;
}

.ServicesHero3-page-content .ServicesHero3-main-title {
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  font-size: 26px;
  padding-top: 50px;
  /* padding-left: 300px; */
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
}

@media only screen and (max-width: 768px) {
  .main-ServicesHero3-page {
    background-image: url("../images/img10.png");
    /* background-color: #fff; */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-top: 100px; */
    min-height: 40vh;
  }
  .ServicesHero3-page-content .ServicesHero3-main-title {
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    font-size: 20px;
    -webkit-text-fill-color: #fff; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; */
  }
}
@media only screen and (max-width: 912px) {
  .main-ServicesHero3-page {
    /* background-image: url("../../images//img80.png"); */
    /* background-color: #fff; */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-top: 100px; */
    min-height: 40vh;
  }
  .ServicesHero3-page-content .ServicesHero3-main-title {
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    font-size: 20px;
    -webkit-text-fill-color: #fff; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; */
  }
}

@media only screen and (max-width: 767px) {
  .ServicesHero3-page .ServicesHero3-img-box {
    background-repeat: no-repeat;
    background-size: cover;
    position: static;
  }

  .panel-body {
    justify-content: space-between;
  }

  .ServicesHero3-page .ServicesHero3-page-maincontent {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .ServicesHero3-page .ServicesHero3-page-service-content {
    z-index: 10;
  }
}

@media (min-height: 950px) {
  .main-ServicesHero3-page {
    min-height: 60vh;
  }
}

.ServicesHero3-btn .ant-btn-primary {
  color: #f5b420;
  /* font-size: 16px; */
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
  width: 200px;
  height: 50px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
}
.ServicesHero3 svg {
  color: #000;
  width: 30px;
}
.ServicesHero3-btn .ant-btn {
  background-color: Linear rgba(192, 192, 192, 0.3);
  /* border-radius: 20px; */
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffd2a4;
  border-color: #000;
  background: #000;
}
.ServicesHero3-btn .ServicesHero3-btn-main .ant-btn-primary {
  color: #ffd2a4;
  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ServicesHero3-btn .ant-btn-primary {
  color: #000;
  border-color: #ffd2a4;
  background: #ffd2a4;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ServicesHero3-page-line {
  margin-right: 90%;
  font-weight: 300;
  font-size: 14px;
}

hr:not([size]) {
  height: 5px;
}

element.style {
}
hr:not([size]) {
  height: 5px;
}
hr:not([size]) {
  height: 1px;
}
/* .aboutus-page-line {
        margin-right: 90%;
        font-weight: 300;
        font-size: 14px;
    } */
/* .hr-line1 {
  border: #e74040;
  border-radius: 1px;
  top: 20px;
  border: none;
  height: 10px;
  background: #e74040;
  margin-bottom: 10px;
  margin-right: 90%;
} */
/* @media only screen and (max-width: 768px) {
      .hero-video-box {
        display: none;
      }
    
      .main-hero-page {
        background-image: url("../images/img2.jpg");
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
      }
    } */
@media only screen and (max-width: 414px) {
  .main-ServicesHero3-page {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 375px) {
  .ServicesHero3-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #fff;
    font-size: 49px;
  }
  .main-ServicesHero3-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 390px) {
  .ServicesHero3-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #fff;
    font-size: 51px;
  }
  .main-ServicesHero3-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 393px) {
  .ServicesHero3-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #fff;
    font-size: 51px;
  }
  .main-ServicesHero3-page {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 820px) {
  .main-ServicesHero3-page {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 912px) {
  .main-ServicesHero3-page {
    padding-top: 100px;
  }
  .ServicesHero3-page-content h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;

    color: #fff;
    font-size: 54px;
  }
}
