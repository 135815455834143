.Aboutpart6-page {
  background-color: #1f2a32;
  /* min-height: calc(100vh - 100px); */
}

.Aboutpart6-page h3 {
  /* padding-top: 20%; */
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 44px;
  color: #ffd479;
  margin-bottom: 0%;
}

.Aboutpart6-page .imp-page p {
  font-weight: 100;
  font-size: 20px;
  line-height: 26.28px;
  text-align: justify;
  color: #fff;
}

/* .Aboutpart1-page .Aboutpart1-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  height: 45px;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Aboutpart1-page .Aboutpart1-request-button-yellow .ant-btn-primary {
  color: #fff;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
} */
/* .aboutpart1-btn-btn .ant-btn-primary {
  color: #f5b420;
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
  width: 90px;
} */

.aboutpart6-btn-btn .ant-btn {
  background-color: Linear rgba(192, 192, 192, 0.3);
  /* border-radius: 20px; */
}
/* .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #f5b420;
  border-color: #000;
  background: #000;
}
.aboutpart1-btn-btn .aboutpart1-btn-btn-main .ant-btn-primary {
  color: #f5b420;
  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.aboutpart1-btn-btn .ant-btn-primary {
  color: #000;
  border-color: #f5b420;
  background: #f5b420;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
} */
.About6-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  height: 40px;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Aboutpage-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  height: 40px;
  margin-right: 25%;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Aboutpage-request-button-yellow
  .Aboutpage-request-button
  .ant-form-item-control-input-content {
  flex: none !important;
  max-width: 100% !important;
  text-align: left !important;
  align-items: left !important;
}
@media only screen and (max-width: 768px) {
  .Services1-page-modal .ant-input {
    width: 50%;
    margin-left: 25%;
    font-size: 18px;
    font-weight: 500;
    background-color: transparent;
  }
  .Aboutpage-request-button-yellow .ant-btn-primary {
    color: #fff;
    font-size: 20px;
    height: 40px;
    margin-right: 25%;
    border-color: #efa82d;
    background: #efa82d;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .ant-form-item-explain-error {
    color: #ff4d4f;
    width: 50%;
    margin-left: 10%;
    font-size: 18px;
  }
}
.Services1-page-modal .ant-input {
  width: 50%;
  margin-left: 25%;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  color: #fff !important;
}

.Services2-image-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 10;
  opacity: 0;
  transition-duration: 400ms;
}

.Services2-image-box .hover-img {
  transition-duration: 400ms;
}

.Services2-image-box:hover .hover-img {
  transform: scale(0.85);
  transition-duration: 600ms;
}

.Services2-image-box:hover::after {
  opacity: 1;
  transition-duration: 400ms;
}
