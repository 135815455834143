.footer-page {
  background-color: #1f2a32;
  grid-gap: 30px;
  padding-top: 40px;
  padding-bottom: 10%;
}

.footer-logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.footer-logo-section .footer-number {
  color: #fff;
}
.footer-logo-section .navbar-btn {
  color: #d7905b;
  border-radius: 10px;
}
.footer-heading-one h5 {
  line-height: 28px;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.footer-heading-two h3 {
  font-size: 20px;
  font-weight: 300;
  color: #ffd479;
  line-height: 22.98px;
}
.footer-heading-two p {
  line-height: 28px;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.footer-image-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.footer-image-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /* z-index: 10; */
  opacity: 0;
  transition-duration: 400ms;
}

.footer-image-box .hover-img {
  transition-duration: 400ms;
}

.footer-image-box:hover .hover-img {
  transform: scale(1.05);
  transition-duration: 400ms;
}

.footer-image-box:hover::after {
  opacity: 1;
  transition-duration: 400ms;
}

a {
  color: #242527;
}

.footer-name {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
}

.footer-amount {
  text-decoration: none;
  color: #fff;

  text-align: justify;
  font-size: clamp(16px, 5vw, 18px) !important;
}
.footer-type {
  text-decoration: none;
  color: #fff;

  text-align: justify;
  font-size: 18px;
}

/* .content-pack img {
    border: 10px solid #fff;
    border-radius: 2px;
  } */

.content-pack a {
  text-decoration: none;
  color: #fff;
}
.content-pack a:hover {
  color: #fff;
}
.new-pack-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  border-radius: 0;
  border: none;
  background: #fff;
}

.footer-para-two {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;

  padding-bottom: 10px;
}

.footer-number {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
}
.footer-heading-mail h3 {
  font-size: 20px;
  font-weight: 300;
  color: #ffd479;
  line-height: 22.98px;
}
.footer-heading-mail p {
  font-size: 14px;
  font-weight: 300;
  color: #fafaf5;
  line-height: 28px;
}
/* .footer-social-icons {
  display: flex;
  column-gap: 10px;
  font-size: 22px;
  padding-top: 50px;
} */

.footer-social-icons .footer-icon-item:hover {
  color: #222222;
  transition-duration: 200ms;
  background-color: transparent;
}

.footer-link-box {
  column-gap: 10px;
}

.footer-link-box a {
  font-weight: 400;
  color: #20487a;
  text-decoration: none;
  transition-duration: 200ms;
}
.footer-link-box a:hover {
  color: #fff;
  transition-duration: 200ms;
}

.copyright-content {
  font-weight: 400;
  color: #fff;
}

.copyright-content a {
  color: #fff;
  text-decoration: none;
  transition-duration: 200ms;
}

.copyright-content a:hover {
  color: #fff;
  transition-duration: 200ms;
}
.footer-social-icons .footer-icon-item {
  border-radius: 50%;
  background-color: transparent;
  width: 50px;
  height: 50px;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  cursor: pointer;
  transition-duration: 200ms;
  color: #fff;
}
.footer-para-icon svg {
  color: grey;
  font-size: xx-large;
}
.footer-para-icon {
  column-gap: 50px;
}

@media only screen and (max-width: 1024px) {
  .footer-page {
    padding-top: 10px;
  }
}
