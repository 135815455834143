#Contactpage {
  padding-bottom: 8%;
  min-height: calc(100vh - 100px);
  /* background-color: rgb(92 155 198); */
  /* background-color: rgb(255, 229, 229); */

  background-color: #1f2a32;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}
.Contactpage-page {
  width: 100%;
  /* min-height: calc(100vh - 150px); */
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.Contactpage-form {
  /* display: flex; */

  /* min-height: calc(100vh - 150px); */
}

.cover-page-box {
  /* min-height: calc(100vh - 550px); */
  max-width: 1200px;
  margin: 0 auto;
}
.Contactpage-form {
  /* display: flex; */
  /* column-gap: 10%; */
  /* align-items: center;
      justify-content: center; */
  /* min-height: calc(100vh - 150px); */
}

.Contactpage-form .ant-form-item {
  margin-bottom: 0;
  min-width: 0px;
}
.Contactpage-form .ant-input {
  /* box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      display: inline-block; */
  width: 80%;

  font-size: 20px;
  /* padding: 4px 11px; */
  color: #fff;
  /* font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s; */
}
.Contactpage-page h1 {
  font-style: normal;

  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 80px;

  color: #ffd479;
}
.Contactpage-page h4 {
  font-style: normal;

  font-style: normal;
  font-weight: 300;
  font-size: 58px;
  line-height: 80px;

  color: #ffd479;
}
.Contactpage-page-visit-address1 {
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28.13px;
  align-items: center;
  /* padding-top: 100px; */
  color: #fff;
}
.Contactpage-page-visit-address2 {
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28.13px;
  align-items: center;
  /* padding-top: 100px; */
  color: #fff;
}
.Contactpage-image img {
  min-height: 150px;
  object-fit: cover;
}

.Contactpage-page-para3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.13px;
  align-items: center;
  /* padding-top: 100px; */
  color: #fff;
}
.Contactpage-page-line hr:not([size]) {
  height: 1px;
  width: 70px;
  color: #fff;
}

/* .contact-page-visit h1 {
      font-family: "Poppins", sans-serif !important;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
    
      color: #dbdbdb;
    } */
.Contactpage-page-big-textarea {
  padding-top: 0px;
}
.Contactpage-social-icons {
  display: flex;
  column-gap: 10px;
  font-size: 22px;
  padding-top: 0px;
}
.Contactpage-social-icons .Contactpage-icon-item {
  border-radius: 50%;
  background-color: transparent;

  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 200ms;
  color: #ffd479;
}
.Contactpage-social-icons .Contactpage-icon-item:hover {
  color: #ffd479;
  transition-duration: 200ms;
  background-color: transparent;
}
.Contactpage-page-visit h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;
  padding-bottom: 1%;
  color: #ffd479;
}
.Contactpage-page-para3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;

  color: #ffd2a4;
}
.Contactpage-btn .ant-btn-primary {
  color: #ffd2a4;
  /* font-size: 16px; */
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
  width: 80%;
  height: 50px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
}
.Contactpage-btn .ant-btn-primary {
  border-radius: 5px;
  width: 80%;
  height: 50px;
  color: #ffd479;
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
}

.Contactpage-btn .ant-btn {
  border-radius: 5px;
  width: 80%;
  height: 50px;
  background-color: Linear rgba(192, 192, 192, 0.3);
  color: #000;
  background: #ffd479;
  border-color: #ffd479;
  /* border-radius: 20px; */
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffd479;
  border-color: #000;
  background: #000;
}
.Contactpage-btn .Contactpage-btn-main .ant-btn-primary {
  color: #ffd479;
  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Contactpage-btn .ant-btn-primary {
  color: #000;
  border-color: #ffd479;
  background: #ffd479;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Contactpage-page-line h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;

  color: #ffd479;
}
.Contactpage-para-icon svg {
  color: grey;
  font-size: xx-large;
}
.Contactpage-page-box {
  color: #fff !important;
}
.lastContactpage-heading h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;
  padding-bottom: 1%;
  color: #ffd479 !important;
}
:where(.css-dev-only-do-not-override-16qxzfl).ant-form-item
  .ant-form-item-label
  > label {
  color: #fff !important;
  font-size: 14px;
}
:where(.css-dev-only-do-not-override-17gkgmp).ant-form-item
  .ant-form-item-label
  > label {
  color: #fff !important;
  font-size: 14px;
}
:where(.css-dev-only-do-not-override-17gkgmp).ant-form-item {
  color: #fff !important;
}
:where(.css-dev-only-do-not-override-19g5zt5)[class^="ant-col"], :where(.css-dev-only-do-not-override-19g5zt5)[class*=" ant-col"] {
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  font-size: 16px;
  box-sizing: border-box;
}