.ant-drawer-content-wrapper {
  width: 100vw !important;

  background-color: #16242f !important;
}

/* .ant-drawer-body {
  background-color: #2d4b9d !important;
}
.ant-drawer-header {
  background-color: #2d4b9d !important;
} */
.ant-drawer-content {
  /* background: linear-gradient( 60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100% ) !important; */
}

/* .ant-drawer-content {
  background-color: #1f2a32 !important;
} */
/* .Navbarbutton-page .Navbarbutton-page-button {
  color: #1f2a32 !important;
  background-color: #1f2a32 !important;
  border-color: #1f2a32 !important;
} */
.Navbarbutton-page {
  cursor: pointer;
  border-radius: 50%;
  height: 70px;
  align-items: center;
  text-align: center;
  justify-content: end;
  display: flex;
}
.ant-drawer-close {
  font-weight: 700;
  font-size: 56px;
}
.main-navlink-page {
  font-size: clamp(25px, 4rem, 43.75px);
  color: white;
}
.main-navlink-page a {
  color: #fff;
}
.ant-drawer-header-title svg {
  color: #fff;
  font-size: 28px;
}
hr:not([size]) {
  height: 5px;
}

element.style {
}
hr:not([size]) {
  height: 5px;
}
hr:not([size]) {
  height: 1px;
}
/* .aboutus-page-line {
      margin-right: 90%;
      font-weight: 300;
      font-size: 14px;
  } */
.hr-line1 {
  border: #1f2a32 !important;
  border-radius: 1px;
  top: 20px;
  border: none;
  height: 0.5px;
  background: #1f2a32 !important;
  /* margin-bottom: 10px;
  margin-right: 90%; */
}
.main-navlink-page-first:hover {
  text-decoration: underline;
  color: #fff;
}
.main-navlink-page-second:hover {
  text-decoration: underline;
  color: #fff;
}
.main-navlink-page-third:hover {
  text-decoration: underline;
  color: #fff;
}
.main-navlink-page-fourth:hover {
  text-decoration: underline;
  color: #fff;
}
.main-navlink-page-fifth:hover {
  text-decoration: underline;
  color: #fff;
}

.ant-drawer-header {
  display: flex;
  flex: 0 1;
  align-items: center;
  /* padding: 16px 24px; */
  font-size: 16px;
  line-height: 22px;
  background-color: #1f2a32 !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background: #1f2a32 !important;
}
/* @media only screen and (max-width: 768px) {
  .Navbarbutton-page .ant-btn {
    display: none;
  }

.heading {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 18px;
}
} */
@media only screen and (max-width: 375px) {
  

  .navbar-para-icon .heading {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 18px!important;
  line-height: 1.6;
}
}
@media only screen and (max-width: 393px) {
  

  .navbar-para-icon .heading {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 18px!important;
  line-height: 1.6;
}
}
body {
  margin: 0;
}

p {
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: relative;
  text-align: center;
  background-color: #1f2a32 !important;
  color: white;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}
.ant-drawer-body {
  /* flex: 1 1; */
  /* min-width: 0; */
  /* min-height: 0; */
  padding: 0px !important;
  overflow: auto;
}
.navbar-para-icon svg {
  color: #fff;
  font-size: medium;
}
.navbar-para-icon {
  align-items: center;
  text-align: center;
  justify-content: start;
  display: flex;
}
.navbar-para-icon .heading {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 28px;
}
