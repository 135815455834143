.title-word {
  animation: color-animation 6s linear infinite;
}

.title-word-1 {
  --color-1: orange;
  --color-2: #ffc94b;
  --color-3: #fadb85;
}
@media only screen and (max-width: 375px) {
  .title {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 46px!important;
    /* margin-top: 10%; */
    line-height: 1.1;
    padding-top: 0px;
  }
  .container-name {
    display: grid;
    place-items: center;  
    text-align: center;
    
  }
}
@media only screen and (max-width: 768px) {
  .title {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 46px!important;
    /* margin-top: 10%; */
    line-height: 1.1;
  }
  .container-name {
    display: grid;
    place-items: center;  
    text-align: center;
    
  }

}
@media only screen and (max-width: 820px) {
  .title {
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    letter-spacing: 7.5px;
    color: #ffd479;
    font-size: 36px!important;
    /* margin-top: 10%; */
    line-height: 1.1;
  }
  .container-name {
    display: grid;
    place-items: center;  
    text-align: center;
   
  }
}
/* .title-word-2 {
  --color-1: #DBAD4A;
  --color-2: #ACCFCB;
  --color-3: #17494D;
}

.title-word-3 {
  --color-1: #ACCFCB;
  --color-2: #E4A9A8;
  --color-3: #ACCFCB;
}

.title-word-4 {
  --color-1: #3D8DAE;
  --color-2: #DF8453;
  --color-3: #E4A9A8;
} */

@keyframes color-animation {
  0%    {color: var(--color-1)}
  32%   {color: var(--color-1)}
  33%   {color: var(--color-2)}
  65%   {color: var(--color-2)}
  66%   {color: var(--color-3)}
  99%   {color: var(--color-3)}
  100%  {color: var(--color-1)}
}

/* Here are just some visual styles. 🖌 */

.container-name {
  display: grid;
  place-items: center;  
  text-align: center;
 
}

.title {
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  letter-spacing: 7.5px;
  color: #ffd479;
  font-size: 86px;
  /* margin-top: 10%; */
  line-height: 1.1;
}