#contact {
  padding-bottom: 15%;
  padding-top: 10px;
  min-height: calc(100vh - 100px);
  /* background-color: rgb(92 155 198); */
  /* background-image: url("../../images/img24.png"); */
  background-color: #1f2a32;
  background-repeat: no-repeat;
  background-size: cover;
}
.con-page {
  width: 100%;
  /* min-height: calc(100vh - 150px); */
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.contact-form {
  /* display: flex; */

  /* min-height: calc(100vh - 150px); */
}
.contact-main-page {
  background: #1e1e1e;
}
.cover-page-box {
  /* min-height: calc(100vh - 550px); */
  max-width: 1200px;
  margin: 0 auto;
}
.contact-form {
  /* display: flex; */
  /* column-gap: 10%; */
  /* align-items: center;
      justify-content: center; */
  /* min-height: calc(100vh - 150px); */
}

.contact-form .ant-form-item {
  margin-bottom: 0;
  min-width: 0px;
  font-size: normal;
}
.contact-form .ant-input {
  /* box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      display: inline-block; */
  width: 100%;

  /* padding: 4px 11px; */
  color: rgba(0, 0, 0, 0.85);
  /* font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s; */
}
.con-page h1 {
  font-style: normal;

  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
  padding-top: 3%;
  color: #ffd479;
}
.con-page p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  padding-bottom: 2%;
  color: #000;
  font-size: 16px;
  line-height: 23px;
}
.contact-page-visit-address1 {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22.99px;
  align-items: center;
  /* padding-top: 100px; */
  color: #000;
}
.contact-page-visit-address2 {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22.99px;
  align-items: center;
  /* padding-top: 100px; */
  color: #000;
}
.con-image img {
  min-height: 150px;
  object-fit: cover;
}

.contact-page-box {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;

  /* padding-top: 5%; */
  color: #fff;
}
.contact-page-line hr:not([size]) {
  height: 1px;
  width: 70px;
  color: #000;
}

/* .contact-page-visit h1 {
      font-family: "Poppins", sans-serif !important;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
    
      color: #dbdbdb;
    } */
.contact-page-big-textarea {
  padding-top: 0px;
}
.contact-social-icons {
  display: flex;
  column-gap: 10px;
  font-size: 22px;
  padding-top: 0px;
}
.contact-social-icons .contact-icon-item {
  border-radius: 50%;
  background-color: transparent;

  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 200ms;
  color: #000;
}
.contact-social-icons .contact-icon-item:hover {
  color: #000;
  transition-duration: 200ms;
  background-color: transparent;
}
.contact-page-visit h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 60px;
  align-items: center;
  padding-top: 20%;
  color: #ffd479;
}

.contact-page-para3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 60px;
  align-items: center;
  padding-top: 12%;
  color: #000;
}

.contact-btn .ant-btn-primary {
  color: #ffc94b;
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
}

.contact-btn .ant-btn {
  background-color: Linear rgba(192, 192, 192, 0.3);
  color: #000;
  background: #ffc94b;
  border-color: #ffc94b;
  height: 40px;
  width: 100px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffc94b;
  border-color: #000;
  background: #000;
}
.contact-btn .contact-btn-main .ant-btn-primary {
  color: #ffd479;
  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}


.contact-page-box .ant-input {
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: rgb(217, 217, 217);
  border-left-color: #fff;
  font-size: 24px;
  color: white;
  font-size: normal;
}

:where(.css-dev-only-do-not-override-1ehggu4).ant-picker {
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
  font-size: 24px;
  font-size: normal;
}
:where(.css-dev-only-do-not-override-qofb5).ant-select-single
  .ant-select-selector {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "Roboto", sans-serif !important;

  border-radius: 0px;
}
.contact-form .ant-picker {
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
  font-size: 24px;
  font-size: normal;
  width: 100%;
  background-color: #1f2a32;
}
.contact-form
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
  font-size: 24px;
  font-size: normal;
  background-color: #1f2a32;
}
:where(.css-dev-only-do-not-override-qofb5).ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
}
.ant-picker-input > input {
  font-size: 24px !important;
  width: 100%;
  font-size: normal !important;
}

.contact-form .ant-input {
  width: 100%;
  height: 100%;
  font-size: 24px;
  /* padding: 4px 11px; */
  color: #f8f9fa !important;
  background-color: #1f2a32;
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
}
:where(.css-dev-only-do-not-override-qofb5).ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #fff !important;
}
:where(.css-dev-only-do-not-override-1ehggu4).ant-form-item
  .ant-form-item-label
  > label {
  color: #fff !important;
  font-size: 14px !important;
}
/* :where(.css-dev-only-do-not-override-qofb5).ant-select-single.ant-select-open
  .ant-select-selection-item:hover {
  color: #fff !important;
} */
:where(.css-dev-only-do-not-override-qofb5).ant-picker
  .ant-picker-input
  > input {
  color: #fff !important;
  font-size: 18px !important;
}
:where(.css-dev-only-do-not-override-qofb5).ant-select-single
  .ant-select-selector {
  color: #fff !important;
  font-size: 18px !important;
}
:where(.css-dev-only-do-not-override-19g5zt5).ant-select-single
  .ant-select-selector {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff !important;
  font-size: 18px !important;
}
:where(.css-dev-only-do-not-override-19g5zt5).ant-form-item
  .ant-form-item-label
  > label {
  color: #fff !important;
  font-size: 18px !important;
}
:where(.css-dev-only-do-not-override-1ehggu4).ant-form-item {
  color: #fff !important;
  font-size: 18px !important;
}
:where(.css-dev-only-do-not-override-1ehggu4).ant-form-item
  .ant-form-item-label
  > label {
  color: #fff !important;
  font-size: 14px !important;
}
