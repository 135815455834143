.head-style h1 {
  padding-top: 50px;
  color: #242527;
  font-weight: 300;
  text-decoration: none;
  font-size: 40px;
}
.main-Services2-page {
  /* padding-top: 5%; */
  min-height: calc(100vh - 100px);
  padding-bottom: 10%;
}

.Services2-image-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.Services2-page-heading h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #ffd479;
  font-size: 96px;
  line-height: 112px;
  text-transform: uppercase;
}

.Services2-image-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 10;
  opacity: 0;
  transition-duration: 400ms;
}

.Services2-image-box .hover-img {
  transition-duration: 400ms;
}

.Services2-image-box:hover .hover-img {
  transform: scale(0.85);
  transition-duration: 600ms;
}

.Services2-image-box:hover::after {
  opacity: 1;
  transition-duration: 400ms;
}

a {
  color: #fff;
}

.Services2-name {
  text-decoration: none;
  color: #ffc94b;
  font-weight: 300;
  font-size: 28px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.Services2-amount {
  text-decoration: none;
  color: #fff;

  text-align: justify;
  font-size: clamp(16px, 5vw, 18px) !important;
}
.Services2-type {
  text-decoration: none;
  color: #fff;
  font-weight: 100;
  text-align: justify;
  font-size: 18px;
}

/* .content-pack img {
  border: 10px solid #fff;
  border-radius: 2px;
} */

.content-pack a {
  text-decoration: none;
  color: #fff;
}
.content-pack a:hover {
  color: #fff;
}
.new-pack-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #fff;

  border-radius: 0;
  border: none;
  background: linear-gradient(97.65deg, #60bbee 0.33%, #0a72ad 93.35%);
}
.main-Services2-page {
  background-color: #1f2a32;
}
.Services2-box {
  height: 40px !important;
  display: flex;
  background-color: #fff;
  align-items: center;
  padding: 30px 20px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
}
.Services2-box .ant-btn-primary {
  background-color: #0a72ad;
  color: #fff;
  border-radius: 0;
  border: none;
}

.Services2-box .anticon {
  vertical-align: 2px;
}
.card-body {
  padding-top: 25px;
}
.Services2-page-line {
  margin-right: 90%;
  font-weight: 300;
  font-size: 14px;
}
.Services2-request-button-yellow .ant-btn-primary {
  color: #242527;
  font-size: 16px;
  height: 40px;
  border-color: #ffc94b;
  background: #ffc94b;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Services2-request-button-yellow .ant-btn-primary:hover {
  color: #ffc94b;
  font-size: 16px;
  height: 40px;
  border-color: #242527;;
  background: #242527;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-carousel .slick-dots li.slick-active button {
  background: #212529;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .Services2-page-heading h1 {
    padding-top: 40px;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    font-size: clamp(2rem, 5vw, 3rem);
    color: #ffd479;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 7.5px;
  }
}
