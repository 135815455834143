.main-about-page {
  background-color: #1f2a32;
  translate: none;
  rotate: none;
  scale: none;

  /* transform: translateY(-50%); */
  padding: 140px 0 100px;
}

.about-page p {
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color: #7e7e7e;
}
.about-page-image1 img {
  width: 100%;
  border-radius: 30px;
}
.about-page-image3 img {
  width: 100%;
  border-radius: 30px;
}
.about-page-image2 img {
  width: 100%;
  border-radius: 32px;
}
/* .about-page-image5 img {
  width: 100%;
  border-radius: 30px;
}
.about-page-image6 img {
  width: 100%;
  border-radius: 30px;
}
.about-page-image10 img {
  width: 100%;
  border-radius: 32px;
} */
.about-page-heading h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #ffd479;
  font-size: 96px;
  line-height: 112px;
}

.about-page-para1 p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;

  color: #000;
  font-size: 16px;
  line-height: 18.75px;
}
.about-page-para2 p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  padding-bottom: 2%;
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.about-btn .ant-btn-primary {
  color: #f5b420;
  /* font-size: 16px; */
  border-color: #000;
  background-color: Linear rgba(78, 70, 70, 0.3);
  width: 200px;
  height: 50px;

  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
}

.about-btn .ant-btn {
  background-color: Linear rgba(192, 192, 192, 0.3);

  /* border-radius: 20px; */
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffd2a4;
  border-color: #000;
  background: #000;
}
.about-btn .about-btn-main .ant-btn-primary {
  color: #ffd2a4;

  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.about-btn .ant-btn-primary {
  color: #000;
  /* padding-top: 5%; */
  border-color: #ffd2a4;
  background: #ffd2a4;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.about-page-heading-bottom1 h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #000;
  font-size: 32px;
  line-height: 44px;
}
.about-page-para-bottom1 p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #000;
  font-size: 16px;
  line-height: 18.75px;
  padding-top: 5%;
}
.about-page-para-bottom2 p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.about-page-para-bottom2 p {
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #000;
  font-size: 14px;
  line-height: 16px;
}
.about-arrow svg {
  font-size: larger;
}
@media only screen and (max-width: 768px) {
  .about-page-heading h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: clamp(2rem, 5vw, 3rem);
    color: #ffd479;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 7.5px;
  }
}

.img1-image-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.img1-image-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 10;
  opacity: 0;
  transition-duration: 400ms;
}

.img1-image-box .hover-img {
  transition-duration: 400ms;
}

.img1-image-box:hover .hover-img {
  transform: scale(1.09);
  transition-duration: 600ms;
}

.img1-image-box:hover::after {
  opacity: 1;
  transition-duration: 400ms;
}
