#contactus {
  padding-bottom: 8%;
  min-height: calc(100vh - 100px);
  /* background-color: rgb(92 155 198); */
  /* background-color: rgb(255, 229, 229); */

  background-color: #1f2a32;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}
.contact-page {
  width: 100%;
  /* min-height: calc(100vh - 150px); */
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.cover-page-box {
  /* min-height: calc(100vh - 550px); */
  max-width: 1200px;
  margin: 0 auto;
}

.contact-form .ant-form-item {
  margin-bottom: 0;
  min-width: 0px;
}

.contact-page h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #ffd479;
  font-size: 96px;
  line-height: 112px;
  text-transform: uppercase;
}

.contact-page-visit h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;
  padding-bottom: 1%;
  color: #1e1b1b;
}
.contact-page-para3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;

  color: #1e1b1b;
}

.contact-page-line h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  align-items: center;

  color: #1e1b1b;
}
.contact-para-icon svg {
  color: #000;
  font-size: xx-large;
  column-gap: 30px;
}
.contactus-form .ant-input {
  width: 90%;
  font-size: 40px;
  /* padding: 4px 11px; */
  color: #f8f9fa !important;
  background-color: #1f2a32;
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
}
:where(.css-dev-only-do-not-override-19g5zt5).ant-input {
  border-color: #1f2a32 !important;
  border-top-color: r#1f2a32 !important;
  border-right-color: #1f2a32 !important;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-left-color: #1f2a32 !important;
  border-radius: 0px;
}
.contact-page-box h2 {
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
}
@media only screen and (max-width: 768px) {
  .contact-page h1 {
    /* padding-top: 40px; */
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    font-size: clamp(2rem, 5vw, 3rem);
    color: #ffd479;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 7.5px;
  }
}
