.Aboutpart5-page {
  background-color: #1f2a32;
  /* min-height: calc(100vh - 100px); */
}

.Aboutpart5-page h3 {
  /* padding-top: 20%; */
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 44px;
  color: #ffd479;
  margin-bottom: 0%;
}

.Aboutpart5-page .imp-page p {
  font-weight: 100;
  font-size: 20px;
  line-height: 26.28px;
  text-align: justify;
  color: #fff;
}

.Aboutpart5-page h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  color: #fff;
}
.Aboutpart5-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 18px;
  height: 40px;
  margin-right: 40%;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Aboutpart5-request-button-yellow .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  height: 40px;
  margin-right: 25%;
  border-color: #efa82d;
  background: #efa82d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.Aboutpart5-request-button-yellow
  .Aboutpage-request-button
  .ant-form-item-control-input-content {
  flex: none !important;
  max-width: 100% !important;
  text-align: left !important;
  align-items: left !important;
}
@media only screen and (max-width: 768px) {
  .Services1-page-modal .ant-input {
    width: 50%;
    margin-left: 25%;
    font-size: 18px;
    font-weight: 500;
    background-color: transparent;
  }
  .Aboutpage-request-button-yellow .ant-btn-primary {
    color: #fff;
    font-size: 20px;
    height: 40px;
    margin-right: 25%;
    border-color: #efa82d;
    background: #efa82d;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .ant-form-item-explain-error {
    color: #ff4d4f;
    width: 50%;
    margin-left: 10%;
    font-size: 18px;
  }
}
.Services1-page-modal .ant-input {
  width: 50%;
  margin-left: 25%;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
}
.ant-form-item-explain-error {
  color: #ff4d4f;
  width: 50%;
  margin-left: 35%;
  font-size: 18px;
}
