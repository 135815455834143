.main-Weare-page {
  background-color: #1f2a32;

  padding:50px  0 120px;
}
.Weare-page-heading h1 {
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: clamp(2rem, 5vw, 3rem);
  color: #ffd479;
  font-size: 96px;
  line-height: 112px;
  
}

.Weare-page p {
  font-size: 32px;
  font-weight: 200;
  line-height: 52.54px;
  text-align: center;
  color: #fff;
  animation-name: paragraph;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes paragraph {
  0% {transform: translateX(-1000px)}
}
.Weare-btn .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #162431;
  border-color: #ffc94b;
  background: #ffc94b;
  width: 200px;
}

a {
  text-decoration: none;
}
a {
  color: #162431 !important;
}
.Weare-btn .ant-btn-primary {
  color: #162431;
  border-color: #162431;
  background: #ffc94b;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  width: 200px;
  height: 60px;
  font-weight: 300;
  font-size: 20px;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .Weare-page-heading h1 {
    -webkit-font-smoothing: antialiased;
    font-weight: 200;
    font-size: clamp(2rem, 5vw, 3rem);
    color: #ffd479;
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 7.5px;
  }
  .main-Weare-page {
    background-color: #1f2a32;
    padding: 0px 0 0px;
  }
  .Weare-page p {
    font-size: 16px;
    font-weight: 100;
    line-height: 32.54px;
    text-align: center;
    color: #fff;
  }
}
