.success-btn .ant-btn-primary {
  color: #fff;
  border-color: #f5b420;
  background: #f5b420;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary {
  color: #fff;
  border-color: #f5b420;
  background: #f5b420;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.success-btn .ant-btn {
  background-color: Linear rgba(192, 192, 192, 0.3);
  /* border-radius: 20px; */
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #f5b420;
  border-color: #000;
  background: #000;
}

.success-btn .success-btn-main .ant-btn-primary {
  color: #f5b420;
  border-color: Linear rgba(78, 70, 70, 0.3);
  background: Linear rgba(78, 70, 70, 0.3);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.success-btn .ant-btn-primary {
  color: #000;
  border-color: #f5b420;
  background: #f5b420;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.content-color {
  color: #fff;
}

.success-btn .ant-result .ant-result-title {
  color: #fff !important;

  text-align: center;
}

.success-btn .ant-result .ant-result-subtitle {
  color: #fff !important;
}

.success-btn .ant-result .ant-result-title {
  color: #fff !important;
}

.success-btn .ant-result .ant-result-subtitle {
  color: #fff !important;
}

.success-btn .ant-result .ant-result-title {
  color: #fff;
  font-size: 24px;
  line-height: 1.3333333333333333;
  margin-block: 8px;
  text-align: center;
}

.success-btn .ant-result .ant-result-subtitle {
  color: #ffff;
  font-size: 14px;
  line-height: 1.5714285714285714;
  text-align: center;
}